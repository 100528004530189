import { Transaction } from '../models/transaction'
import { TransactionResponse, TransactionResponsev2, TransactionSummary } from '../models/http'
import { ChargerInfo } from '../models/chargerv2'
import { mockCarRef } from './car-mock'

export const mockTransactions = [
  {
    id: 21565,
    startMethod: 'RFID',
    percent: 48.5,
    percentStart: null,
    currentState: 'no_reservation',
    currentChargingDuration: 21357,
    startAt: '2021-06-24T21:10:05.000-04:00',
    chargeStartAt: '2021-06-24T21:10:05.000-04:00',
    chargeEndAt: '2021-06-25T05:18:43.000Z',
    timezone: 'America/Toronto',
    duration: 14912,
    energy: 26905.031,
    tenant: {
      id: '21565',
      name: 'swtchhq',
      displayName: '6045-6085 Creditview Road, Mississauga, ON (Credit Valley Town Plaza)',
    },
    financial: {
      subtotal: 0,
      total: 0,
      tax: 0,
      serviceFee: 0,
      tenantAmount: 0,
      propertySubtotal: 0,
      propertyTax: 0,
      currency: 'CAD',
    },
    charger: {
      id: 68,
      chargePointSerialNumber: '1EC1-1-1824-00014',
      chargePointVendor: 'ChargePoint',
      chargingType: 'Level 2',
    },
    user: {
      id: 'Uxy3k0VTzsgSdCYhQ7d57w',
      email: 'mikebucketsp9r8hwfelsfnvwp3r98fhweuinjksdfq0f8hcudsnjck23r0fhieowsdc230fhwonedlkc@gmail.com',
      name: 'Mike',
    },
    listing: {
      id: 67,
      title: 'VC03',
    },
    connector: {
      id: 71,
      connectorId: 1,
      connectorType: 'J1772',
    },
    car: null,
  },
  {
    id: 21566,
    startMethod: 'RFID',
    percent: 32.8,
    percentStart: null,
    currentState: 'confirmed',
    currentChargingDuration: 15653,
    startAt: '2021-06-24T20:43:13.000-04:00',
    endAt: '2021-06-25T15:30:59.000Z',
    chargeStartAt: '2021-06-24T21:43:13.000-04:00',
    chargeEndAt: '2021-06-25T05:04:12.000Z',
    timezone: 'America/Toronto',
    duration: 15653,
    energy: 29048.12,
    tenant: {
      id: '4',
      name: 'swtchhq',
      displayName: '7-Queen-Elizabeth-blvd',
    },
    financial: {
      subtotal: 435,
      total: 1492,
      tax: 57,
      serviceFee: 50,
      tenantAmount: 442,
      propertySubtotal: 0,
      propertyTax: 3,
      currency: 'USD',
    },
    charger: {
      id: 69,
      chargePointSerialNumber: '1EC1-1-1824-00009',
      chargePointVendor: 'Phi-Phong',
      chargingType: 'Level 2',
    },
    user: {
      id: 'gDLLnZzzDV1QXzbaKvwCIw',
      email: 'canadanreynolds@gmail.com',
      name: 'Dan',
    },
    listing: {
      id: 68,
      title: 'VC04',
    },
    connector: {
      id: 72,
      connectorId: 1,
      connectorType: 'CHAdeMO',
    },
    car: mockCarRef,
  },
  {
    id: 21561,
    startMethod: 'website',
    percent: 78.0,
    currentState: 'errored',
    currentChargingDuration: 68,
    startAt: '2021-06-24T19:52:48.000-04:00',
    endAt: '2021-06-24T23:53:09.000Z',
    chargeStartAt: '2021-06-24T19:53:01.000Z',
    // chargeEndAt: null,
    timezone: 'America/Toronto',
    duration: 0,
    energy: null,
    tenant: {
      id: '21561',
      name: 'swtchhq',
      displayName: 'Erskine Ave Place',
    },
    financial: {
      subtotal: 0,
      total: 0,
      tax: 0,
      serviceFee: 0,
      tenantAmount: 0,
      propertySubtotal: 0,
      propertyTax: 0,
      currency: 'USD',
    },
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'ChargePoint',
      chargingType: 'DC',
    },
    user: {
      id: 'yCcmQZxNAGrG0WTgq6uUTQ',
      email: 'yeluyupt@gmail.com',
      name: 'Lyuyu',
    },
    listing: {
      id: 72,
      title: 'VC08',
    },
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 21562,
    startMethod: 'website',
    percent: 12.0,
    currentState: 'completed',
    currentChargingDuration: 44,
    startAt: '2021-06-24T19:52:48.000-04:00',
    endAt: '2021-06-24T23:53:09.000Z',
    chargeStartAt: '2021-06-24T19:53:01.000Z',
    // chargeEndAt: null,
    timezone: 'America/Toronto',
    duration: 3,
    energy: null,
    tenant: {
      id: '21564',
      name: '440-450 Columbia Street West, Waterloo, ON (Laurelwood Shopping Centre)',
      displayName: '440-450 Columbia Street West, Waterloo, ON (Laurelwood Shopping Centre)',
    },
    financial: {
      subtotal: 14,
      total: 23,
      tax: 2,
      serviceFee: 0,
      tenantAmount: 0,
      propertySubtotal: 0,
      propertyTax: 0,
      currency: 'USD',
    },
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'ChargePoint',
      chargingType: 'DC',
    },
    user: {
      id: 'yCcmQZxNAGrG0WTgq6uUTQ',
      email: 'yeluyupt@gmail.com',
      name: 'Lyuyu',
    },
    listing: {
      id: 72,
      title: 'VC08',
    },
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
  {
    id: 21567,
    startMethod: 'rfid',
    percent: 12.0,
    currentState: 'completed',
    currentChargingDuration: 44,
    startAt: '2021-06-25T19:52:48.000-04:00',
    endAt: '2021-06-25T23:53:09.000Z',
    chargeStartAt: '2021-06-24T19:53:01.000Z',
    // chargeEndAt: null,
    timezone: 'America/Toronto',
    duration: 3,
    energy: 2342,
    tenant: {
      id: '21565',
      name: '1 Yonge Street, Toronto, ON M3N 1S7',
      displayName: '1 Yonge Street, Toronto, ON M3N 1S7',
    },
    financial: {
      subtotal: 14,
      total: 23,
      tax: 2,
      serviceFee: 0,
      tenantAmount: 0,
      propertySubtotal: 0,
      propertyTax: 0,
      currency: 'USD',
    },
    charger: {
      id: 73,
      chargePointSerialNumber: '1EC1-1-1824-00028',
      chargePointVendor: 'ChargePoint',
      chargingType: 'DC',
    },
    user: {
      id: 'yCcmQZxNAGrG0WTgq6uUTQ',
      email: 'michael.vu+payter+329bd2e296d75a2001460de2aee12d855c8347ac513275c7f95b63a7c730cce3@swtchenergy.com',
      name: 'Annonymous Guest',
    },
    listing: {
      id: 72,
      title: 'VC10',
    },
    connector: {
      id: 76,
      connectorId: 1,
      connectorType: 'CCS',
    },
    car: null,
  },
] as Transaction[]

export const mockTransactionResp = (filter: any) => {
  return {
    data: mockTransactions,
    pagination: {
      currentPage: filter.page,
      totalEntries: mockTransactions.length,
      perPage: 10,
    },
    filters: {},
  } as TransactionResponse
}

export const mockTransactionRespv2 = {
  txnCount: 1565,
  pageCount: 157,
  nextPage: 2,
  perPage: 10,
  transactions: [
    {
      txnId: 228751,
      startMethod: 'Mobile',
      plugInDuration: 1156,
      connected: '2023-02-05 18:27:17-0500',
      disconnected: null,
      energy: 3165,
      state: 'pending_payment',
      total: 0,
      username: 'plugncharge',
    },
    {
      txnId: 228740,
      startMethod: 'Mobile',
      plugInDuration: 37567,
      connected: '2023-02-05 18:09:16-0500',
      disconnected: '2023-02-06 10:55:48-0500',
      energy: 64078,
      state: 'confirmed',
      total: 1180,
      username: 'plugncharge',
    },
    {
      txnId: 228714,
      startMethod: 'RFID',
      plugInDuration: 10157,
      connected: '2023-02-05 17:38:21-0500',
      disconnected: '2023-02-05 20:27:38-0500',
      energy: 8575,
      state: 'confirmed',
      total: 159,
      username: 'plugncharge',
    },
    {
      txnId: 228705,
      startMethod: 'Mobile',
      plugInDuration: 7159,
      connected: '2023-02-05 17:31:43-0500',
      disconnected: '2023-02-05 19:31:02-0500',
      energy: 12412,
      state: 'free',
      total: 0,
      username: 'plugncharge',
    },
    {
      txnId: 228674,
      startMethod: 'Mobile',
      plugInDuration: 3130,
      connected: '2023-02-05 16:58:00-0500',
      disconnected: '2023-02-05 17:50:10-0500',
      energy: 18676,
      state: 'confirmed',
      total: 1768,
      username: 'plugncharge',
    },
    {
      txnId: 228671,
      startMethod: 'Mobile',
      plugInDuration: 5386,
      connected: '2023-02-05 16:54:13-0500',
      disconnected: '2023-02-05 18:23:59-0500',
      energy: 9311,
      state: 'free',
      total: 0,
      username: 'plugncharge',
    },
    {
      txnId: 228658,
      startMethod: 'Mobile',
      plugInDuration: 6329,
      connected: '2023-02-05 13:40:59-0800',
      disconnected: '2023-02-05 16:32:43-0800',
      energy: 11464,
      state: 'free',
      total: 0,
      username: 'plugncharge',
    },
    {
      txnId: 228648,
      startMethod: 'RFID',
      plugInDuration: 16200,
      connected: '2023-02-05 16:32:08-0500',
      disconnected: '2023-02-05 21:02:08-0500',
      energy: 14825,
      state: 'confirmed',
      total: 254,
      username: 'plugncharge',
    },
    {
      txnId: 228629,
      startMethod: 'Mobile',
      plugInDuration: 8940,
      connected: '2023-02-05 16:10:49-0500',
      disconnected: '2023-02-05 18:39:49-0500',
      energy: 15788,
      state: 'confirmed',
      total: 280,
      username: 'plugncharge',
    },
    {
      txnId: 228619,
      startMethod: 'Mobile',
      plugInDuration: 25068,
      connected: '2023-02-05 15:56:10-0500',
      disconnected: '2023-02-05 22:53:58-0500',
      energy: 45411,
      state: 'confirmed',
      total: 696,
      username: 'plugncharge',
    },
  ],
} as TransactionResponsev2

export const mockTransactionSummary = {
  txnOverview: [
    {
      datetime: '2022-01-01T00:00:00',
      revenue: 7853,
      txnCount: 3,
      energy: 35210,
    },
    {
      datetime: '2022-01-02T00:00:00',
      revenue: 9053,
      txnCount: 5,
      energy: 50210,
    },
    {
      datetime: '2022-01-03T00:00:00',
      revenue: 5053,
      txnCount: 15,
      energy: 40210,
    },
    {
      datetime: '2022-01-04T00:00:00',
      revenue: 8053,
      txnCount: 15,
      energy: 40210,
    },
  ],
  chargers: [
    {
      time: '00:00', //HH:mm
      chargerInUseWeekday: 8, //integer
      chargerInUseWeekend: 19, //integer
      chargerInUseWeek: 24, //integer
    },
    {
      time: '01:00',
      chargerInUseWeekday: 29,
      chargerInUseWeekend: 21,
      chargerInUseWeek: 24,
    },
    {
      time: '02:00',
      chargerInUseWeekday: 18,
      chargerInUseWeekend: 22,
      chargerInUseWeek: 22,
    },
    {
      time: '03:00',
      chargerInUseWeekday: 35,
      chargerInUseWeekend: 26,
      chargerInUseWeek: 23,
    },
    {
      time: '04:00',
      chargerInUseWeekday: 36,
      chargerInUseWeekend: 24,
      chargerInUseWeek: 27,
    },
    {
      time: '05:00',
      chargerInUseWeekday: 35,
      chargerInUseWeekend: 24,
      chargerInUseWeek: 27,
    },
    {
      time: '06:00',
      chargerInUseWeekday: 15,
      chargerInUseWeekend: 20,
      chargerInUseWeek: 25,
    },
    {
      time: '07:00',
      chargerInUseWeekday: 24,
      chargerInUseWeekend: 36,
      chargerInUseWeek: 27,
    },
    {
      time: '08:00',
      chargerInUseWeekday: 28,
      chargerInUseWeekend: 23,
      chargerInUseWeek: 24,
    },
    {
      time: '09:00',
      chargerInUseWeekday: 15,
      chargerInUseWeekend: 24,
      chargerInUseWeek: 23,
    },
    {
      time: '10:00',
      chargerInUseWeekday: 17,
      chargerInUseWeekend: 16,
      chargerInUseWeek: 15,
    },
    {
      time: '11:00',
      chargerInUseWeekday: 38,
      chargerInUseWeekend: 30,
      chargerInUseWeek: 27,
    },
    {
      time: '12:00',
      chargerInUseWeekday: 16,
      chargerInUseWeekend: 27,
      chargerInUseWeek: 24,
    },
    {
      time: '13:00',
      chargerInUseWeekday: 5,
      chargerInUseWeekend: 23,
      chargerInUseWeek: 28,
    },
    {
      time: '14:00',
      chargerInUseWeekday: 14,
      chargerInUseWeekend: 16,
      chargerInUseWeek: 15,
    },
    {
      time: '15:00',
      chargerInUseWeekday: 13,
      chargerInUseWeekend: 27,
      chargerInUseWeek: 26,
    },
    {
      time: '16:00',
      chargerInUseWeekday: 22,
      chargerInUseWeekend: 24,
      chargerInUseWeek: 27,
    },
    {
      time: '17:00',
      chargerInUseWeekday: 11,
      chargerInUseWeekend: 27,
      chargerInUseWeek: 29,
    },
    {
      time: '18:00',
      chargerInUseWeekday: 18,
      chargerInUseWeekend: 27,
      chargerInUseWeek: 26,
    },
    {
      time: '19:00',
      chargerInUseWeekday: 25,
      chargerInUseWeekend: 27,
      chargerInUseWeek: 29,
    },
    {
      time: '20:00',
      chargerInUseWeekday: 24,
      chargerInUseWeekend: 23,
      chargerInUseWeek: 25,
    },
    {
      time: '21:00',
      chargerInUseWeekday: 32,
      chargerInUseWeekend: 24,
      chargerInUseWeek: 27,
    },
    {
      time: '22:00',
      chargerInUseWeekday: 29,
      chargerInUseWeekend: 15,
      chargerInUseWeek: 24,
    },

    {
      time: '23:00',
      chargerInUseWeekday: 30,
      chargerInUseWeekend: 15,
      chargerInUseWeek: 27,
    },
  ],
  dailyProfiles: [
    {
      time: '00:00', //HH:mm
      consumeWeekday: 10301, //unit wh, integer
      consumeWeekend: 10101, //unit wh, integer
      consumeWeek: 10100, //unit wh, integer
    },
    {
      time: '01:00',
      consumeWeekday: 20301,
      consumeWeekend: 20101,
      consumeWeek: 20100,
    },
    {
      time: '02:00',
      consumeWeekday: 30301,
      consumeWeekend: 30101,
      consumeWeek: 30100,
    },
    {
      time: '03:00',
      consumeWeekday: 40301,
      consumeWeekend: 40101,
      consumeWeek: 40100,
    },
    {
      time: '04:00',
      consumeWeekday: 50301,
      consumeWeekend: 50101,
      consumeWeek: 50100,
    },
    {
      time: '05:00',
      consumeWeekday: 60301,
      consumeWeekend: 60101,
      consumeWeek: 60100,
    },
    {
      time: '06:00',
      consumeWeekday: 70301,
      consumeWeekend: 70101,
      consumeWeek: 70100,
    },
    {
      time: '07:00',
      consumeWeekday: 80301,
      consumeWeekend: 80101,
      consumeWeek: 80100,
    },
    {
      time: '08:00',
      consumeWeekday: 90301,
      consumeWeekend: 90101,
      consumeWeek: 90100,
    },
    {
      time: '09:00',
      consumeWeekday: 100301,
      consumeWeekend: 100101,
      consumeWeek: 100100,
    },
    {
      time: '10:00',
      consumeWeekday: 110301,
      consumeWeekend: 110101,
      consumeWeek: 110100,
    },
    {
      time: '11:00',
      consumeWeekday: 120301,
      consumeWeekend: 120101,
      consumeWeek: 120100,
    },
    {
      time: '12:00',
      consumeWeekday: 130301,
      consumeWeekend: 130101,
      consumeWeek: 130100,
    },
    {
      time: '13:00',
      consumeWeekday: 140301,
      consumeWeekend: 140101,
      consumeWeek: 140100,
    },
    {
      time: '14:00',
      consumeWeekday: 150301,
      consumeWeekend: 150101,
      consumeWeek: 150100,
    },
    {
      time: '15:00',
      consumeWeekday: 160301,
      consumeWeekend: 160101,
      consumeWeek: 160100,
    },
    {
      time: '16:00',
      consumeWeekday: 170301,
      consumeWeekend: 170101,
      consumeWeek: 170100,
    },
    {
      time: '17:00',
      consumeWeekday: 180301,
      consumeWeekend: 180101,
      consumeWeek: 180100,
    },
    {
      time: '18:00',
      consumeWeekday: 190301,
      consumeWeekend: 190101,
      consumeWeek: 190100,
    },
    {
      time: '19:00',
      consumeWeekday: 100301,
      consumeWeekend: 200101,
      consumeWeek: 200100,
    },
    {
      time: '20:00',
      consumeWeekday: 210301,
      consumeWeekend: 210101,
      consumeWeek: 210100,
    },
    {
      time: '21:00',
      consumeWeekday: 220301,
      consumeWeekend: 230101,
      consumeWeek: 230100,
    },
    {
      time: '22:00',
      consumeWeekday: 230301,
      consumeWeekend: 230101,
      consumeWeek: 230100,
    },
    {
      time: '23:00',
      consumeWeekday: 240301,
      consumeWeekend: 240101,
      consumeWeek: 240100,
    },
  ],
  summary: {
    txnCount: 4163,
    txnAfterHr: 1326,
    txnOnDays: 1225,
    revenueTotal: 1387657,
    revenueDemandResponse: 0,
    revenueCharging: 1166143,
    revenueLoitering: 221514,
    energyConsumeTotal: 71754675,
    energyConsumeInPeakHrs: 28450458,
    ghgSavingTotal: 52380913,
    chargerAverageDayDuration: 9837,
    chargerAverageDayLevel2Duration: 10985,
    chargerAverageDayDcfcDuration: 2815,
    chargerAverageDayPlugin: 15514,
    chargerAverageDayLevel2Plugin: 17584,
    chargerAverageDayDcfcPlugin: 2857,
    usersTotal: 582,
    avgTxnDay: 47,
    avgEnergyConsumePerTxn: 17236,
    avgUserSpend: 280,
    stationActivation: {
      mobile: 50,
      web: 10,
      rfid: 10,
      roaming: 30,
    },
  },
  timezone: 'America/Toronto',
} as TransactionSummary

export const mockChargerInfo = {
  users: {
    active: 0, //integer
    guest: 0, //integer
    accountHolder: 0, //integer
  },
  connectors: {
    pluggedIn: 0,
    pluggedInLevel2: 0,
    pluggedInDc: 0,
    available: 0,
    availableLevel2: 0,
    availableDc: 0,
    drProgram: 0,
    drProgramLevel2: 0,
    drProgramDc: 0,
    charging: 0,
    totalConnectors: 0,
  },
  chargers: {
    online: 50, //integer
    offline: 30, //integer
    repair: 20, //integer
    maxAmp: 0, //unit A, integer
    currentAmp: 640, //unit A, float with decimal 2
  },
} as ChargerInfo
